var render = function () {
  var _vm$resellTicket, _vm$resellTicket2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BModal', {
    attrs: {
      "id": "modal-resell-logs",
      "title": _vm.$t('resellTicket.btn.logsDetail'),
      "title-class": "text-airline font-weight-bolder ".concat(_vm.isMobileView ? 'font-medium-2' : 'font-medium-4'),
      "body-class": "p-1",
      "size": "lg",
      "centered": "",
      "hide-footer": ""
    },
    on: {
      "show": _vm.showHandle
    }
  }, [(_vm$resellTicket = _vm.resellTicket) !== null && _vm$resellTicket !== void 0 && _vm$resellTicket.logs ? _c('b-table', {
    staticClass: "mb-1 rounded",
    attrs: {
      "bordered": "",
      "responsive": "",
      "sticky-header": true,
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "no-border-collapse": "",
      "small": "",
      "thead-class": "text-dark font-weight-bolder text-nowrap",
      "items": (_vm$resellTicket2 = _vm.resellTicket) === null || _vm$resellTicket2 === void 0 ? void 0 : _vm$resellTicket2.logs,
      "fields": _vm.tableColumns
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column,
            staticClass: "text-dark"
          }, [_vm._v(" " + _vm._s(_vm.$t("resellTicket.columns.".concat(data.column))) + " ")])];
        }
      };
    }), {
      key: "cell(no)",
      fn: function fn(data) {
        var _vm$resellTicket3;
        return [_vm._v(" " + _vm._s(((_vm$resellTicket3 = _vm.resellTicket) === null || _vm$resellTicket3 === void 0 ? void 0 : _vm$resellTicket3.logs.length) - Number(data.index)) + " ")];
      }
    }, {
      key: "cell(updatedAt)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(data.item.updatedAt).timeDate) + " ")])];
      }
    }, {
      key: "cell(modifiedBy)",
      fn: function fn(data) {
        var _data$item$modifiedBy;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s((_data$item$modifiedBy = data.item.modifiedBy) === null || _data$item$modifiedBy === void 0 ? void 0 : _data$item$modifiedBy.username) + " ")])];
      }
    }, {
      key: "cell(oldValue)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.action === 'UPDATE' ? _c('div', {
          staticClass: "text-justify"
        }, _vm._l(Object.keys(JSON.parse(item.oldValue)), function (valueItem, valueIndex) {
          return _c('div', {
            key: valueIndex
          }, [_vm._v(" " + _vm._s(valueItem) + ": "), typeof JSON.parse(item.oldValue)[valueItem] === 'number' ? _c('span', [_vm._v(_vm._s(_vm.formatCurrency(JSON.parse(item.oldValue)[valueItem])))]) : _c('span', [_vm._v(_vm._s(JSON.parse(item.oldValue)[valueItem]))])]);
        }), 0) : _vm._e()];
      }
    }, {
      key: "cell(newValue)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.action === 'CREATE' ? _c('div', [_vm._v(" " + _vm._s(_vm.$t('resellTicket.create')) + " ")]) : item.action === 'UPDATE' ? _c('div', {
          staticClass: "text-justify"
        }, _vm._l(Object.keys(JSON.parse(item.newValue)), function (valueItem, valueIndex) {
          return _c('div', {
            key: valueIndex
          }, [_vm._v(" " + _vm._s(valueItem) + ": "), typeof JSON.parse(item.newValue)[valueItem] === 'number' ? _c('span', [_vm._v(_vm._s(_vm.formatCurrency(JSON.parse(item.newValue)[valueItem])))]) : _c('span', [_vm._v(_vm._s(JSON.parse(item.newValue)[valueItem]))])]);
        }), 0) : _vm._e()];
      }
    }], null, true)
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template lang="">
  <BModal
    id="modal-resell-logs"
    :title="$t('resellTicket.btn.logsDetail')"
    :title-class="`text-airline font-weight-bolder ${isMobileView ? 'font-medium-2' : 'font-medium-4'}`"
    body-class="p-1"
    size="lg"
    centered
    hide-footer
    @show="showHandle"
  >
    <b-table
      v-if="resellTicket?.logs"
      bordered
      responsive
      :sticky-header="true"
      show-empty
      :empty-text="$t('noMatchingResult')"
      no-border-collapse
      small
      class="mb-1 rounded"
      thead-class="text-dark font-weight-bolder text-nowrap"
      :items="resellTicket?.logs"
      :fields="tableColumns"
    >
      <template
        v-for="column in tableColumns"
        v-slot:[`head(${column})`]="data"
      >
        <span
          :key="column"
          class="text-dark"
        >
          {{ $t(`resellTicket.columns.${data.column}`) }}
        </span>
      </template>
      <template #cell(no)="data">
        {{ resellTicket?.logs.length - Number(data.index) }}
      </template>

      <template #cell(updatedAt)="data">
        <div class="text-nowrap">
          {{ convertISODateTime(data.item.updatedAt).timeDate }}
        </div>
      </template>

      <template #cell(modifiedBy)="data">
        <div class="text-nowrap">
          {{ data.item.modifiedBy?.username }}
        </div>
      </template>

      <template #cell(oldValue)="{ item }">
        <div
          v-if="item.action === 'UPDATE'"
          class="text-justify"
        >
          <div
            v-for="(valueItem, valueIndex) in Object.keys(JSON.parse(item.oldValue))"
            :key="valueIndex"
          >
            {{ valueItem }}:
            <span v-if="typeof JSON.parse(item.oldValue)[valueItem] === 'number'">{{ formatCurrency(JSON.parse(item.oldValue)[valueItem]) }}</span>
            <span v-else>{{ JSON.parse(item.oldValue)[valueItem] }}</span>
          </div>
        </div>
      </template>

      <template #cell(newValue)="{ item }">
        <div v-if="item.action === 'CREATE'">
          {{ $t('resellTicket.create') }}
        </div>
        <div
          v-else-if="item.action === 'UPDATE'"
          class="text-justify"
        >
          <div
            v-for="(valueItem, valueIndex) in Object.keys(JSON.parse(item.newValue))"
            :key="valueIndex"
          >
            {{ valueItem }}:
            <span v-if="typeof JSON.parse(item.newValue)[valueItem] === 'number'">{{ formatCurrency(JSON.parse(item.newValue)[valueItem]) }}</span>
            <span v-else>{{ JSON.parse(item.newValue)[valueItem] }}</span>
          </div>
        </div>
      </template>
    </b-table>
  </BModal>
</template>
<script>
import { BModal, BTable } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

import { convertISODateTime, formatCurrency } from '@core/utils/filter'

export default {
  components: { BModal, BTable },
  props: {
    resellTicket: {
      type: [Object, null],
      default: null,
    },
  },
  setup() {
    const tableColumns = ref(['no', 'updatedAt', 'modifiedBy', 'newValue', 'oldValue'])
    function showHandle() {

    }
    return {
      showHandle, tableColumns, convertISODateTime, formatCurrency,
    }
  },
}
</script>
<style lang="">

</style>
